
import React from "react";

export const Crousel = (props) => {


    return (
        <div id="gallery" className="text-center crousal-main">
            <h1>{props.title}</h1>
            <div className="crousel-contain">

                <div className="owl-carousel owl-theme ">
                    {props.data
                        ? props.data.map((d, i) => (
                            <div
                                key={`${d.title}-${i}`}
                                className="item"
                            >
                                <img src={d.largeImage} alt="" />
                            </div>
                        ))
                        : "Loading..."}


                </div>
            </div>
        </div>
    );
};
{/* <div className="owl-carousel owl-theme ">
    <div className="item"><h4><img src="/img/portfolio/01-large.jpg" alt="" /></h4></div>
    <div className="item"><h4><img src="/img/portfolio/01-large.jpg" alt="" /></h4></div>
    <div className="item"><h4><img src="/img/portfolio/01-large.jpg" alt="" /></h4></div>
    <div className="item"><h4><img src="/img/portfolio/01-large.jpg" alt="" /></h4></div>

</div> */}