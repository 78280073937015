import React from "react";

export const Features = (props) => {
  // console.log("props.data", props.data);
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Commitments</h2>

        </div>
        <div className="row ">
          {props.data
            ? props.data.map((d, i) => {
              console.log("d",d.text);
              return (
                <div key={`${d.name}-${i}`} className="col-md-4 ">
                  {" "}
                  
                  <div className="icon-img"><img className={d.icon} src={d.icon} width='70px' alt="" /></div>
                  {/* <i className={d.icon}></i> */}
                  <div className="service-desc">
                  <h3>{d.name}</h3>
                  {/* <span>{d.text}</span> */}
                    
                    <p>{d.text}</p>
                  </div>
                </div>
              )
            })
            : "loading"}
        </div>
      </div>
    </div>
  );
};
