import { useEffect, useState } from "react";
import React from "react";
import toast, { Toaster } from "react-hot-toast";

// import "react-toastify/dist/ReactToastify.css";
const initialState = {
  name: "",
  email: "",
  number: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, number, message }, setState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  console.log("name", name);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim()) {
      showToast("Name is required");
      return;
    }
    if (!email.trim()) {
      showToast("Email is required");
      return;
    }
    if (!number.trim()) {
      showToast("Phone is required");
      return;
    }
    if (!message.trim()) {
      showToast("Message is required");
      return;
    }

    setIsLoading(true);

    const data = {
      name,
      email,
      number,
      message,
    };
    try {
      const response = await fetch(
        "https://mail-sender-blush.vercel.app/send",
        {
          // const response = await fetch("http://localhost:5000/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      console.log("response", response);
      if (response.ok) {
        showToast("Message sent successfully!", true);
        // toast.success("Message sent successfully!");

        clearState();
      } else {
        showToast("Failed to send message. Please try again.", false);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      showToast("Error sending message. Please try again later.", false);
    } finally {
      setIsLoading(false);
    }
  };

  const clearState = () => setState({ ...initialState });

  const showToast = (message, success) => {
    if (success) {
      toast.success(message, {
        // icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } else {
      toast.error(message, {
        // icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    // alert(message); // You can replace this with a proper toast notification
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        value={name}
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="number"
                        name="number"
                        value={number}
                        className="form-control"
                        placeholder="Phone"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    value={message}
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  {isLoading ? "Sending..." : "Send Message"}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                <a href="tel: +1 812 645 5579" target="blank" className="contact-text">

                  {props.data ? props.data.phone : "loading"}
                </a> <br />
                
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-whatsapp"></i> WhatsApp
                </span>{" "}
                <a href="https://wa.me/447928062002" target="blank" className="contact-text">

                  {props.data ? props.data.whatsapp : "loading"}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <a href="mailto:sales@patchmakers.us" target="blank" className="contact-text">
                  {props.data ? props.data.email : "loading"}
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a
                      href={props.data ? props.data.facebook : "/"}
                      target="blank"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={props.data ? props.data.instagram : "/"}
                      target="blank"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={props.data ? props.data.youtube : "/"}
                      target="blank"
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={props.data ? props.data.linkdin : "/"}
                      target="blank"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        {/* <div className="container text-center">
          <p>
            &copy; 2023 Issaaf Kattan React Land Page Template. Design by{" "}
            <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a>
          </p>
        </div> */}
      </div>
    </div>
  );
};
